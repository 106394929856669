<template>
  <div class="user-center-container" v-loading="loading">
    <div class="container">
      <div class="user-center-tab">
        <div style="margin-bottom: -1px">
          <div class="user-center-tab-border">
            <div
              :class="
                tabIndex === index
                  ? 'user-center-tab-item active'
                  : 'user-center-tab-item'
              "
              v-for="(item, index) in tabs"
              :key="index"
              @click="tabIndex = index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="user-info-content" v-if="tabIndex === 0">
        <el-form
          class="user-form"
          ref="userForm"
          :rules="userRule"
          :model="userForm"
          label-width="80px"
        >
          <el-form-item prop="uname" label="用户名">
            <span>{{ userForm.uname }}</span>
          </el-form-item>
          <el-form-item label="真实姓名" prop="realname">
            <span v-if="!editing"> {{ userForm.realname || "-" }}</span>
            <el-input
              v-model="userForm.realname"
              v-else
              class="w-280"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <span v-if="!editing">******</span>
            <el-input
              v-model="userForm.password"
              class="w-280"
              v-else
              type="password"
            ></el-input>
          </el-form-item>
          <transition name="slide-fade">
            <el-form-item
              label="密码确认"
              prop="password_confirmation"
              v-if="userForm.password"
            >
              <el-input
                v-model="userForm.password_confirmation"
                class="w-280"
                type="password"
              ></el-input>
            </el-form-item>
          </transition>
        </el-form>
        <el-button
          class="user-button"
          type="primary"
          @click="handleSubmit"
          size="medium"
          v-if="editing"
          v-loading="submitLoading"
          >保存</el-button
        >
        <el-button
          class="user-button"
          type="primary"
          size="medium"
          @click="toEdit"
          v-else
          >编辑</el-button
        >
      </div>
      <div class="phone-email-content" v-if="tabIndex === 1">
        <el-form
          :model="phoneForm"
          ref="phoneForm"
          size="small"
          label-width="80px"
        >
          <el-form-item label="手机">
            <span v-if="!clickBind">{{ phoneForm.phone_number || "-" }}</span>
            <el-button
              class="ml-12"
              type="text"
              @click="handleUnbind"
              v-if="phoneForm.phone_number && !clickBind"
              >解绑手机</el-button
            >
            <el-button
              type="text"
              class="ml-12"
              v-if="!phoneForm.phone_number && !clickBind"
              @click="clickBind = true"
              >绑定手机</el-button
            >
            <el-row>
              <el-input
                label="请输入手机号"
                class="w-280 mb-12"
                placeholder="请输入手机号"
                v-model="phoneForm.phone_number"
                v-if="clickBind"
              >
              </el-input>
            </el-row>
            <el-row>
              <el-input
                placeholder="请输入手机验证码"
                v-model="phoneForm.code"
                class="w-280 mb-12"
                v-if="clickBind"
              >
                <el-button
                  slot="append"
                  style="width: 130px"
                  @click="sendCode"
                  :disabled="disabled"
                  class="code-btn"
                  >{{ btnText }}</el-button
                >
              </el-input>
            </el-row>
            <el-button
              v-if="clickBind"
              @click="handleBind"
              v-loading="bindLoading"
              >立即绑定</el-button
            >
          </el-form-item>

          <el-form-item label="邮箱">
            <span v-if="!emailClickBind">{{ phoneForm.email || "-" }}</span>
            <el-button
              type="text"
              class="ml-12"
              @click="handleEmailUnbind"
              v-if="phoneForm.email && !emailClickBind"
              >解绑邮箱</el-button
            >
            <el-button
              type="text"
              class="ml-12"
              v-if="!phoneForm.email && !emailClickBind"
              @click="emailClickBind = true"
              >绑定邮箱</el-button
            >
            <el-row>
              <el-input
                placeholder="请输入邮箱"
                prop="email"
                class="w-280 mb-12"
                v-model="phoneForm.email"
                v-if="emailClickBind"
              >
                <el-button
                  slot="append"
                  @click="sendEmail"
                  :disabled="sended"
                  style="width: 100px"
                  class="code-btn"
                  >{{ sended ? "已发送" : "发送验证码" }}</el-button
                ></el-input
              >
            </el-row>
            <el-row>
              <el-input
                placeholder="请输入邮箱验证码"
                class="w-160 mb-12"
                v-model="phoneForm.email_code"
                v-if="emailClickBind"
              >
              </el-input>
            </el-row>
            <el-button
              v-if="emailClickBind"
              @click="handleEmailBind"
              v-loading="emailBindLoading"
              >立即绑定</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex";
import authService from "@/global/service/auth";
export default {
  components: {},
  data() {
    const valiPwd = (rule, value, callback) => {
      if (value !== this.userForm.password) {
        return callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      userForm: {
        uname: "",
        realname: "",
        password: "",
      },
      phoneForm: {
        phone_number: "",
        email: "",
      },
      userRule: {
        realname: {
          required: true,
          message: "真实姓名不能为空",
          trigger: "blur",
        },
        password_confirmation: {
          validator: valiPwd,
          message: "两次密码输入不一致",
          trigger: "blur",
        },
      },
      genderConst: [
        { label: "保密", value: 0 },
        { label: "男", value: 1 },
        { label: "女", value: 2 },
      ],
      districtOptions: [],
      editing: false,
      tabs: ["个人信息", "手机与邮箱"],
      tabIndex: 0,
      sended: false,
      disabled: false,
      btnText: "发送验证码",
      clickBind: false, //是否点击了绑定
      emailClickBind: false,
      bindLoading: false,
      submitLoading: false,
      emailBindLoading: false,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.userForm = {
      uname: this.userInfo.uname,
      realname: this.userInfo.realname,
    };
    this.phoneForm = {
      email: this.userInfo.email,
      phone_number: this.userInfo.phone_number,
    };
  },
  methods: {
    getUserInfo() {
      authService.userProfile().then((res) => {
        this.userForm = { uname: res.uname, realname: res.realname };
        this.phoneForm = { email: res.email, phone_number: res.phone_number };
      });
    },
    handleSubmit() {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          const params = {
            realname: this.userForm.realname,
          };
          if (this.userForm.password) {
            params.password = this.userForm.password;
            params.password_confirmation = this.userForm.password_confirmation;
          }
          authService.userSetting(params).then(() => {
            this.$message.success("保存成功");
            this.userForm.password = "";
            this.userForm.password_confirmation = "";
            this.submitLoading = false;
            this.editing = false;
          });
        }
      });
    },
    getDistricts() {},
    toEdit() {
      this.editing = true;
    },
    sendCode() {
      if (!this.phoneForm.phone_number.length) {
        return this.$message.error("请先输入手机号");
      }
      authService
        .phoneSms({
          phone_number: this.phoneForm.phone_number,
        })
        .then(() => {
          let time = 60;
          this.btnText = `${time} 秒后重新发送`;
          this.disabled = true;
          const interval = setInterval(() => {
            time--;
            this.btnText = `${time} 秒后重新发送`;
            if (time === 0) {
              clearInterval(interval);
              this.btnText = "重新发送";
              this.disabled = false;
            }
          }, 1000);
        });
    },
    handleBind() {
      if (!this.phoneForm.phone_number.length || !this.phoneForm.code.length) {
        return this.$message.error("请确保手机号与验证码都填写之后再进行绑定");
      }
      if (this.bindLoading) {
        return;
      }
      this.bindLoading = true;
      const params = {
        phone_number: this.phoneForm.phone_number,
        code: this.phoneForm.code,
      };
      authService
        .phoneBind(params)
        .then(() => {
          this.$message.success("绑定成功");
          this.clickBind = false;
          this.phoneForm.code = "";
        })
        .finally(() => {
          this.bindLoading = false;
        });
    },
    handleUnbind() {
      this.$confirm(
        `确认取消绑定 ${this.phoneForm.phone_number} 的手机号?`
      ).then(() => {
        authService.phoneUnbind().then(() => {
          this.phoneForm.phone_number = "";
          this.$message.success("解绑成功");
        });
      });
    },
    handleEmailUnbind() {
      this.$confirm(`确认取消绑定 ${this.phoneForm.email} 的邮箱？`).then(
        () => {
          authService.emailUnbind().then(() => {
            this.phoneForm.email = "";
            this.$message.success("解绑成功");
          });
        }
      );
    },
    handleEmailBind() {
      if (!this.phoneForm.email.length || !this.phoneForm.email_code.length) {
        return this.$message.error("请确保邮箱与验证码都填写之后再进行绑定");
      }
      if (this.emailBindLoading) {
        return;
      }
      this.emailBindLoading = true;
      const params = {
        email: this.phoneForm.email,
        code: this.phoneForm.email_code,
      };
      authService
        .emailBind(params)
        .then(() => {
          this.$message.success("绑定成功");
          this.emailClickBind = false;
          this.phoneForm.email_code = "";
        })
        .finally(() => {
          this.emailBindLoading = false;
        });
    },
    sendEmail() {
      const email = this.phoneForm.email;
      authService.emailSend({ email }).then(() => {
        this.sended = true;
      });
    },
  },
};
</script>

<style type="text/css" lang="less" scoped>
.user-center-container {
  flex: 1;
}
.user-title {
  font-size: 28px;
  color: #333;
  font-weight: 600;
  margin-bottom: 30px;
}
.user-content {
  padding: 20px 30px;
}
.container {
  width: 880px;
  margin: 0 auto;
  padding: 50px 120px 50px;
  background: #fff;
  min-height: 800px;
}
.user-form {
  width: 430px;
}
.user-form-input {
  width: 100% !important;
}
.user-button {
  margin-left: 80px;
}
.upload-image-avatar-container {
  width: 50px;
  height: 50px;
}
.user-center-tab {
  display: flex;
  border-bottom: 1px solid #d8d8d8;

  .user-center-tab-border {
    display: flex;
    border: 1px solid #d8d8d8;
    border-bottom: none;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
  }
  .user-center-tab-item {
    border-bottom: 1px solid transparent;
    padding: 0px 18px;
    height: 38px;
    line-height: 38px;
    cursor: pointer;
    transition: all 0.2s;
    &.active {
      color: #35b558;
      border-bottom-color: #fff;
    }
    &:last-child {
      border-left: 1px solid #d8d8d8;
    }
  }
}
</style>

<style lang="less">
.user-center-container {
  .user-content {
    .user-form {
      .el-form-item {
        margin-bottom: 35px;
      }
      .el-form-item__label {
        width: 100px;
        padding-right: 30px;
      }
      .el-form-item__content {
        margin-left: 100px;
        width: 340px;
      }
    }
  }
  .user-info-content {
    padding: 20px 0;
  }
  .phone-email-content {
    padding: 20px 0;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
